import React, { useEffect, useState } from "react";
import { Box, Button } from "@mui/material";
import { toast } from "react-toastify";
import * as XLSX from "xlsx";
import { Form } from "react-bootstrap";
import { BASE_API } from "../../../../common/services";
import ProgressBar from "../ProgressBar";
import PaginateAutoComplete from "../../../../common/components/custom-ui/PaginateAutoComplete";
// import PreviewTable from "../../Table/PreviewTable";
// import ResponseTable from "../../Table/ResponseTable";
// import ImportResultsModal from "../ImportResultsModal";

const BatterySection = ({
  selectedFile,
  setSelectedFile,
  fileData,
  setFileData,
  loading,
  setLoading,
  resultData,
  setResultData,
  updatedData,
  setUpdatedData,
  setHeaderColumns,
  uploadFunction,
  setUploadFunction,
  editFunction,
  setEditFunction,
  aedType_list,
  setRejectedRecords,
}) => {
  // const [selectedFile, setSelectedFile] = useState(null);
  // const [fileData, setFileData] = useState([]);
  // const [loading, setLoading] = useState(false);
  // const [resultData, setResultData] = useState([]);
  // const [reportModal,setreportModal] = useState(false);
  // const [updatedData, setUpdatedData] = useState([]);
  const [selectedAccount, setSelectedAccount] = useState("");

  const handleFileChange = (e) => {
    const fileInput = e.target;
    const file = fileInput.files[0];
  
    fileInput.value = null;   // Reset file input

    if (file) {
      const maxSizeInBytes = 26214400; // 25 MB

      if (file.size > maxSizeInBytes) {
        toast.error("File size must be less than 25MB.");
        setSelectedFile(null);
      } else {
        setSelectedFile(file);
        setResultData([]);
        setUploadFunction(false);
      }
    } else {
      setSelectedFile(null);
    }
  };


  // this function stand for  date convert into proper date format from the excel 
  // function excelSerialDateToJSDate(serial) {

  //   if (serial === "unknown") {
  //     return "unknown";
  //   }

  //   if (serial === "" || serial === null || serial === undefined) {
  //     return "";
  //   }

  //   try {
  //     // console.log("Initial serial value:", serial);
  //     // console.log("Type of initial serial value:", typeof serial);
  
  //     // If the serial is already a Date object, format it
  //     if (serial instanceof Date && !isNaN(serial.getTime())) {
  //       return formatDate(serial);
  //     }
  
  //     // Parse string to number if needed
  //     if (typeof serial === 'string') {
  //       const dateFromString = new Date(serial);
  //       if (!isNaN(dateFromString.getTime())) {
  //         return formatDate(dateFromString);
  //       }
  //       serial = parseFloat(serial);
  //     }
  
  //     // Validate serial number
  //     if (typeof serial !== 'number' || serial <= 0 || isNaN(serial)) {
  //       console.error(`Invalid serial number detected: ${serial}`);
  //       return "";
  //     }
  
  //     // Adjust for Excel's leap year bug
  //     if (serial > 59) {
  //       serial -= 1;
  //     }
  
  //     // Excel epoch (January 1, 1900 at midnight UTC)
  //     const EXCEL_EPOCH = new Date(Date.UTC(1900, 0, 1));
  //     const MILLISECONDS_PER_DAY = 24 * 60 * 60 * 1000;
  
  //     // Calculate days and fractional days
  //     const totalDays = Math.floor(serial);
  //     const fractionalDay = serial - totalDays;
      
  //     // Calculate the date
  //     const dateValue = new Date(EXCEL_EPOCH.getTime() + (totalDays - 1) * MILLISECONDS_PER_DAY);
      
  //     // Add the time portion
  //     if (fractionalDay > 0) {
  //       dateValue.setMilliseconds(fractionalDay * MILLISECONDS_PER_DAY);
  //     }
  
  //     return formatDate(dateValue);
  //   } catch (error) {
  //     console.error("Error converting Excel serial to date:", error);
  //     return "";
  //   }
  // }
  
  // // Helper function to format the date
  // function formatDate(date) {
  //   const day = String(date.getDate()).padStart(2, '0');
  //   const month = String(date.getMonth() + 1).padStart(2, '0');
  //   const year = date.getFullYear();
  //   return `${month}/${day}/${year}`;
  // }
  
  const handleSubmit = async (e) => {
    e.preventDefault();

    if(!selectedAccount){
      toast.error("Please select a account.");
      return;
    }
  
    if (!selectedFile) {
      toast.error("Please select a file.");
      return;
    }
  
    setLoading(true);
    try {
      const reader = new FileReader();
      reader.onload = (e) => {
        const fileData = e.target.result;
        const workbook = XLSX.read(fileData, { type: "binary" });
        const sheetName = workbook.SheetNames[0]; // Get the first sheet
        const sheet = workbook.Sheets[sheetName];
        const jsonData = XLSX.utils.sheet_to_json(sheet, { defval: null, raw: false }); // Convert sheet to JSON data

        // const dateFields = [
        //   "battery_expiration",
        //   "manufactured_date",
        //   "install_9v_date",
        //   "install_before_date",
        //   "install_date",
        // ];
  
        // const formattedData = jsonData.map((row) => {
        //   const updatedRow = { ...row };
          
        //   dateFields.forEach((field) => {
        //     if (updatedRow[field]) {
        //       updatedRow[field] = excelSerialDateToJSDate(updatedRow[field]);
        //     }
        //   });
  
        //   return updatedRow;
        // });
  
        console.log({ jsonData });
        setFileData(jsonData); // Store data in the state
      };
  
      reader.readAsBinaryString(selectedFile); // Read the file as binary string
    } catch (error) {
      toast.error("Error processing file.");
    } finally {
      setLoading(false);
    }
  };
  

  const handleUpload = async () => {
    if (!selectedFile) {
      return;
    }

    const formData = new FormData();
    formData.append("file", selectedFile);

    try {
      setLoading(true);
      const token = localStorage.getItem("ross_token");
      if (!token) throw new Error("No Token found");

      let Key = Object.keys(aedType_list).find((key) => aedType_list[key] === "Battery");

      const res = await fetch(`${BASE_API}import/aed-battery-import/${selectedAccount?.accountId}?aed_type=${Key}`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      });

      if (res.status) {
        const result = await res.json();

        const successRecords = result?.data?.successful_records || [];
        if(successRecords.length > 0 ){
          toast.success("File uploaded successfully!");
        }  else{
          toast.error( result?.msg || "No Records Successfully Imported!");
        }

        const rsData = result.data || [];

        // Extracting the "record" only from the rejected_records.
        const rejectedData = rsData?.rejected_records
          ? rsData?.rejected_records.map((item) => item.record)
          : [];
        const failedData = rsData?.failed_records
          ? rsData?.failed_records.map((item) => item.record)
          : [];
        const rejectRecords = [...rejectedData, ...failedData];
        setRejectedRecords(rejectRecords);

        setResultData(result?.data);
        setSelectedFile(null);
        setFileData([]);
        setUploadFunction(false);

        let rejected = result?.data?.summary?.rejected_records || 0;
        let failed = result?.data?.summary?.failed_records || 0;
        if(rejected === 0 && failed === 0) {
          setSelectedAccount("");
        }
      } else {
        toast.error("Failed to upload file.");
      }
    } catch (error) {
      toast.error("Error during file upload.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
   if(uploadFunction === true) {
    handleUpload();
   } else if (editFunction === true) {
    handleEdit();
   }
  }, [uploadFunction, editFunction]);

  const headerColumns = [
    {
      Header: "is_spare",
      accessor: "is_spare",
      disableSortBy: true,
    },
    {
      Header: "battery_part",
      accessor: "battery_part",
      disableSortBy: true,
    },
    {
      Header: "battery_expiration",
      accessor: "battery_expiration",
      disableSortBy: true,
    },
    {
      Header: "battery_lot",
      accessor: "battery_lot",
      disableSortBy: true,
    },
    {
      Header: "battery_udi",
      accessor: "battery_udi",
      disableSortBy: true,
    },
    {
      Header: "battery_serial",
      accessor: "battery_serial",
      disableSortBy: true,
    },
    {
      Header: "install_9v_date",
      accessor: "install_9v_date",
      disableSortBy: true,
    },
    {
      Header: "install_before_date",
      accessor: "install_before_date",
      disableSortBy: true,
    },
    {
      Header: "install_date",
      accessor: "install_date",
      disableSortBy: true,
    },
    {
      Header: "manufactured_date",
      accessor: "manufactured_date",
      disableSortBy: true,
    },
    {
      Header: "serial_number",
      accessor: "serial_number",
      disableSortBy: true,
    },
  ];

  
  useEffect(() => {
    if(headerColumns) {
      setHeaderColumns(headerColumns);
    }
  },[]);


  const handleEdit = async() => {
    const convertToCSV = (data) => {
      const entries = Object.values(data);
      const headers = Object.keys(entries[0]);
    
      const rows = entries.map((row) =>
        headers.map((header) => `"${row[header] ?? ""}"`).join(",") 
      );
      return [headers.join(","), ...rows].join("\n");
    };
    
  
    const csvString = convertToCSV(updatedData);
  
    const blob = new Blob([csvString], { type: "text/csv" });
    const formData = new FormData();
    formData.append("file", blob, "editedData.csv");
    
    try {
      setLoading(true);
      const token = localStorage.getItem("ross_token");
      if (!token) throw new Error("No Token found");

      let Key = Object.keys(aedType_list).find((key) => aedType_list[key] === "Battery");

      const res = await fetch(`${BASE_API}import/aed-battery-import/${selectedAccount?.accountId}?aed_type=${Key}`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      });

      if (res.status) {
        const result = await res.json();

        const successRecords = result?.data?.successful_records || [];
        if(successRecords.length > 0 ){
          toast.success("File uploaded successfully!");
        }  else{
          toast.error( result?.msg || "No Records Successfully Imported!");
        }

        const rsData = result.data || [];

        // Extracting the "record" only from the rejected_records.
        const rejectedData = rsData?.rejected_records ? rsData?.rejected_records.map((item) => item.record) : [];
        const failedData = rsData?.failed_records ? rsData?.failed_records.map((item) => item.record) : [];
        const rejectRecords = [...rejectedData, ...failedData];
        setRejectedRecords(rejectRecords);

        setResultData(result?.data);
        setUpdatedData([]);
        setEditFunction(false);

        let rejected = result?.data?.summary?.rejected_records || 0;
        let failed = result?.data?.summary?.failed_records || 0;
        if(rejected === 0 && failed === 0) {
          setSelectedAccount("");
        }
      } else {
        toast.error("Failed to upload file.");
      }
    } catch (error) {
      toast.error("Error during file upload.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Box className="d-flex" style={{ gap: "1rem" }}>
        {/* File Input Section */}
        <Form onSubmit={handleSubmit}>
          <div className="d-flex">
            <Form.Group style={{ width: "250px", marginRight: "5px" }}>
              <Form.Label>Select Account*</Form.Label>
              <PaginateAutoComplete
                dropDownName={"account_id"}
                apiEndpoint={"account/account-list-dropdown"}
                idKey={"account_id"}
                valueKey={"account_name"}
                parentKey={"allAccount"}
                onSelect={(e, val) => {
                  setSelectedAccount({
                    accountId: val?.account_id,
                    accountName: val?.account_name,
                  });
                }}
                className={"mt-1"}
                shouldFetchOnOpen={false}
                isCache={true}
                selectDisabled={false}
                isSearchable={true}
                isAsync={true}
                pageSize={20}
                defaultValue={{
                  account_id: selectedAccount?.accountId,
                  account_name: selectedAccount?.accountName,
                }}
              />
            </Form.Group>

            <Form.Group
              controlId="fileInput"
              className="d-flex align-items-center"
            >
              <div className="d-flex flex-column">
                <Form.Label>Battery File*</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Select Input File"
                  defaultValue={selectedFile?.name}
                  readOnly
                  style={{ width: "250px" }}
                />
              </div>
              <div className="ms-2 file-input-div">
                <Button
                  variant="link"
                  style={{
                    border: "none",
                    backgroundColor: "transparent",
                    cursor: "pointer",
                    padding: 0,
                  }}
                >
                  <img
                    src="/assets/images/upload.svg"
                    alt="upload"
                    style={{ width: "30px", height: "30px", marginTop: "30px" }}
                  />
                  <Form.Control
                    type="file"
                    name="file_name"
                    accept=".xlsx, xls, .csv"
                    style={{
                      opacity: 0,
                      position: "absolute",
                      width: "100%",
                      height: "100%",
                      cursor: "pointer",
                    }}
                    onChange={handleFileChange}
                  />
                </Button>
              </div>
            </Form.Group>

            {/* Submit Button */}
            <Button
              type="submit"
              variant="contained"
              color="success"
              sx={{ marginTop: "35px" }}
            >
              Submit
            </Button>
          </div>
        </Form>
      </Box>

    {/* Progress Bar */}
    {loading && <ProgressBar />}

  </>
  );
};

export default BatterySection;
