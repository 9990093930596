import React, { useState, useEffect } from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { DecryptToken, getPer } from "../../../common/helper/BasicFn";
import { Box } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setSiteActiveTab } from "../slices/siteSlice";
import { BASE_API, CallGETAPI, CallPOSTAPI } from "../../../common/services";
import Edit from "../../../common/img/Edit.png";
import { toast } from "react-toastify";
import { getPermission } from "../../../common/helper";
import SubHeading from "../../dashboard/components/header/SubHeading";
import Loading from "../../../common/components/Loader/Loading";
import SitesTab from "../components/SitesTab";
import { addNewBreadcrumbs } from "../../../store/slices/breadcrumbsSlice";
import { editSiteDataAdd } from "../../../store/slices/TabSlice";
import { isPermission } from "../../../common/helper/PermissionManager";
import SupportSymbol from "../../Support/Component/SupportSymbol";
import { EquipmentIcon } from "../../../common/helper/icons";
import { AccountSiteTab } from "../../../common/hooks";
import AedReportButton from "../../Aed/NewAed/components/AedButtons/AedReportButton";

export default function SiteMain() {
  const { siteId, accountId } = useParams();
  const { tab } = useParams();
  const activeTab = tab || "Details";
  const [currentTab, setCurrentTab] = useState(AccountSiteTab.Details);
  const [showLoading, setShowLoading] = React.useState(false);
  const [accountData, setAccountData] = React.useState({});
  const [CoordiDataList, setCoordiDataList] = React.useState([]);
  const [siteDataList, setSiteDataList] = React.useState([]);
  const [siteContactList, setSiteContactList] = React.useState([]);
  const [siteData, setSiteData] = React.useState([]);
  const [billingData, setBillingData] = React.useState("");
  const [shippingData, setShippingData] = React.useState("");
  const [trainingData, setTraningData] = React.useState([]);
  const [siteHoursData, setHoursData] = useState("");
  const [aedUnits, setAedUnits] = React.useState([]);
  const [coordinatorData, setCoordinatorData] = React.useState([]);
  const [editSiteAdmin, setEditSiteAdmin] = useState({});
  const [aedLength, setAedLength] = useState();
  const [equipmentData, setEquipmentData] = useState({});
  const [aedAccessoryCount, setAedAccessoryCount] = useState({});
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = DecryptToken();
  const privilege = getPermission();
  const exportReport = true;
  const activeSiteTab = useSelector(
    (state) => state?.TAB_manager?.siteActiveTab
  );

  const editSiteData = useSelector((state) => state?.TAB_manager?.editSiteData);
  const siteName = editSiteData?.siteInfo?.account_site_name
    ? editSiteData?.siteInfo?.account_site_name
    : "";

  const fetchAedLengthApi = async (account_id) => {
    const res = await CallGETAPI(
      `account/aed-accessory-count?account_id=${account_id}&site_id=${siteId}`
    );
    setAedLength(res?.data?.data?.aedCount);
    setAedAccessoryCount(res?.data?.data);
  };

  useEffect(() => {
    fetchAedLengthApi(editSiteData?.siteInfo?.account_id);
  }, []);

  // handle tab
  const handleTab = (item) => {
    dispatch(setSiteActiveTab(item));
    setCurrentTab(item);
  };

  const fetch = async () => {
    try {
      setShowLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setSiteData([]);
      setBillingData({});
      setShippingData({});
      setTraningData([]);
      setHoursData({});
      setCoordinatorData([]);
      setAedUnits([]);
      setShowLoading(false);
    }
  };

  // on load
  useEffect(() => {
    fetch();
  }, [siteId]);

  // set tab
  useEffect(() => {
    if (activeSiteTab) {
      setCurrentTab(activeSiteTab);
    } else if (location?.state?.tab) {
      setCurrentTab(location?.state?.tab);
    }
  }, [location]);

  const [isOpen, setIsOpen] = useState(false);

  const documentRedirect = () => {
    navigate("/account-document-upload", {
      state: {
        accountId: siteData?.account_id,
        type: "site",
        siteId,
      },
    });
  };
  const BreadCrumbsObject = {
    title: "",
    type: "",
    tab: "",
  };
  const handleEditDispatch = (account_id, siteId, accountName) => {
    BreadCrumbsObject.title = "Site Edit";
    BreadCrumbsObject.tab = "Details";
    BreadCrumbsObject.type = "site";

    dispatch(
      addNewBreadcrumbs({
        ...BreadCrumbsObject,
        url: `/admin-siteEdit/${account_id}/${siteId}/SiteEdit`,
      })
    );
  };
  const [documentData, setDocumentData] = useState(null);

  const getDocumentsData = async () => {
    const response = await CallGETAPI(
      `get-all-site-documents?siteId=${siteId}&accountId=${siteData?.account_id}`
    );

    if (response?.status) {
      setDocumentData(response?.data?.data ? response?.data.data : []);
    }
  };

  const [equipmentUrl, setEquipmentUrl] = useState("");
  useEffect(() => {
    if (siteData?.account_id) {
      // getDocumentsData();
      let equipmentUrl1 = "/assign-quipment/" + siteData?.account_id;
      equipmentUrl1 = siteId ? equipmentUrl1 + "/" + siteId : equipmentUrl1;
      setEquipmentUrl(equipmentUrl1);
    }
  }, [siteData]);

  const supportRedirect = () => {
    const stateData = {
      type: "Site",
      site_id: siteId || 0,
      accountId: siteData?.account_id || 0,
      contactId: 0,
      accountName: "",
      support_name: siteData?.account_site_name,
    };

    navigate("/account/new-support/" + siteData?.account_id, {
      state: stateData,
    });
  };

  const notesRedirect = () => {
    const accountId = siteData?.account_id || 0;
    navigate(`/account/new-note?account_id=${accountId}&site_id=${siteId}`);
  };

  useEffect(() => {
    // dispatch(
    //   addItem({ title: "Sites", path: location?.pathname, tab: currentTab })
    // );
  }, [currentTab]);

  const breadcrumbs = useSelector((state) => state.BreadCrumbs.items);

  const handleExportReport = async () => {
    let payload = {
      account_id: siteData?.account_id,
      site_id: siteId,
      tab: currentTab,
    };
    const res = await CallPOSTAPI("admin/report-site", payload);
    const path = res?.data?.filePath;
    handleDownload(path);
  };

  const handleExportAed = async () => {
    let payload = {
      account_id: siteData?.account_id,
      site_id: siteId,
      tab: "aed",
    };
    const res = await CallPOSTAPI("admin/report-site", payload);
    const path = res?.data?.filePath;
    handleDownload(path);
  };

  const handleExportAccessory = async () => {
    let payload = {
      account_id: siteData?.account_id,
      site_id: siteId,
      tab: "accessory",
    };
    const res = await CallPOSTAPI("admin/report-site", payload);
    const path = res?.data?.filePath;
    handleDownload(path);
  };

  const handleDownload = (path) => {
    if (path) {
      const fullURL = BASE_API + path; //`/${path}`;
      // Construct a temporary anchor element
      const link = document.createElement("a");
      link.href = fullURL;
      link.download = "report.csv";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      toast.success("File download successfully");
    } else {
      toast.error("No file to download");
    }
  };

  const [tabTbldata, setTabTbldata] = useState({
    site: false,
    contact: false,
    equipment: {
      aed: false,
      accessory: false,
    },
    note: false,
    support: false,
  });

  const handleHoverFloating = () => {
    setIsOpen(true);
  };

  const handleLeaveFloating = () => {
    setIsOpen(false);
  };

  // console.log({editSiteAdmin})

  useEffect(() => {
    // console.log({siteData})
    if (editSiteAdmin && Object.keys(editSiteAdmin).length > 0) {
      dispatch(editSiteDataAdd(editSiteAdmin));
    }
  }, [editSiteAdmin]);

  useEffect(() => {
    setEquipmentData(() => ({
      aeds: editSiteData?.siteInfo?.aedcount,
      accessory: editSiteData?.siteInfo?.acccount,
    }));
  }, [editSiteData]);

  return (
    <>
      {/* loading */}
      {showLoading ? (
        <>
          <div className="showloading">
            <Loading />
          </div>
        </>
      ) : (
        <>
          <div className="mt-4 pb-5" style={{}}>
            <SubHeading
              title={`Site: ${siteName} `}
              hideNew={true}
              newUrl={"/account/sites/new/" + siteData?.account_id}
              subHeading={true}
              hideHierarchy={true}
              editUrl={false}
              backTab={"Sites"}
              bottomLinks={false}
              support_type="Site"
              support_name={siteData?.account_site_name}
              site_id={siteId}
              account_id={siteData?.account_id}
              assign_equipment={true}
              breadcrumbs={breadcrumbs}
            />

            {/* bottom links */}
            <div
              className="d-flex"
              style={{
                width: "100%",
                justifyContent: "space-between",
                flexWrap: "nowrap",
              }}
            >
              <div className="d-flex">
                {/* <button
                  className="btn text-primary"
                  type="button"
                  onClick={() =>
                    navigate("/account/site-details-edit/" + siteId)
                  }
                >
                  <img
                    src="/edit.svg"
                    alt="edit"
                    style={{ marginRight: "0" }}
                  />
                  <span className="ms-2">Edit</span>
                </button> */}
                {Number(isPermission({ type: "btn", name: "editSite" })) ===
                1 ? (
                  <button
                    className="btn text-primary"
                    type="button"
                    onClick={() => {
                      handleEditDispatch(
                        editSiteData?.siteInfo?.account_id,
                        siteId
                      );
                      navigate(
                        `/admin-siteEdit/${editSiteData?.siteInfo?.account_id}/${siteId}/SiteEdit`,
                        { state: { editSiteAdmin: editSiteAdmin } }
                      );
                    }}
                  >
                    <img src={Edit} alt="edit" style={{ marginRight: "0" }} />
                    <span className="ms-2">Edit</span>
                  </button>
                ) : (
                  ""
                )}

                {aedLength > 0 &&
                  Number(isPermission({ type: "btn", name: "assignedAed" })) ===
                    1 && (
                    <button
                      className="btn text-primary"
                      type="button"
                      onClick={() =>
                        navigate(
                          `/assign-quipment/${editSiteData?.siteInfo?.account_id}/${siteId}`,
                          {
                            state: {
                              siteId: siteId,
                              accountName: editSiteData?.accountName
                                ? editSiteData?.accountName
                                : "",
                              redirectPath: `account/site-details/${siteId}/Equipment`,
                            },
                          }
                        )
                      }
                    >
                      <EquipmentIcon />
                      <span className="ms-1">Assigned AED</span>
                    </button>
                  )}

                {activeTab == AccountSiteTab.Equipment && (
                  <AedReportButton
                    accountId={editSiteData?.siteInfo?.account_id}
                    siteId={siteId}
                    EquipmentData={equipmentData}
                  />
                )}

                {/* {(user?.user_type == 0 ||
                  (user?.user_type == 2 &&
                    user?.sub_admin != "" &&
                    privilege?.includes("assign-aed"))) && (
                  <button
                    className="btn text-primary"
                    type="button"
                    style={{ backgroundColor: "transparent !important" }}
                    onClick={() => navigate(equipmentUrl)}
                  >
                    <img src={EquipmentIcon} alt="EquipmentIcon" style={{width:"25px", height:"25px"}} />
                    <span className="ms-2">Assigned AED</span>
                  </button>
                )} */}

                {/* <button
                  className="btn text-primary"
                  type="button"
                  // onClick={ () => navigate('/account/site-details-edit/' + siteId) }
                >
                  <img src="/assets/images/aed_laws.svg" alt="aed_laws" />
                  <span className="ms-2">AED Laws</span>
                </button> */}
                {/* {exportReport &&
                  (currentTab == "Sites" ||
                    (currentTab == "Contacts" && tabTbldata.contact == true) ||
                    (currentTab == "Notes" && tabTbldata.note == true) ||
                    (currentTab == "Support" &&
                      tabTbldata.support == true)) && (
                    <button
                      className="btn text-primary ms-2 bg-white"
                      id="new-tab-btn-12111"
                      type="button"
                      style={{ backgroundColor: "transparent !important" }}
                      onClick={handleExportReport}
                    >
                      <img
                        src={Report}
                        alt="Report"
                        style={{ width: "25px", height: "24px" }}
                      />
                      <span className="ms-1"> Export Report</span>
                    </button>
                  )} */}

                {/* {exportReport &&
                  currentTab == "Equipment" &&
                  (tabTbldata.equipment.aed == true ||
                    tabTbldata.equipment.accessory == true) && (
                    <Dropdown>
                      <Dropdown.Toggle
                        className="btn btn-transparent text-primary ms-2 bg-white DropDownBtn"
                        id="new-tab-btn"
                        style={{
                          backgroundColor: "transparent !important",
                          border: "none",
                        }}
                      >
                        <img
                          src={Report}
                          alt="Report"
                          style={{ width: "25px", height: "24px" }}
                        />
                        <span className="ms-1 textSize">Export Report</span>
                      </Dropdown.Toggle>

                      <Dropdown.Menu
                        className="bg-primary"
                        style={{ minWidth: "30px" }}
                      >
                        {tabTbldata.equipment.accessory == true && (
                          <Dropdown.Item
                            className=""
                            onClick={handleExportAccessory}
                          >
                            Accessories
                          </Dropdown.Item>
                        )}

                        {tabTbldata.equipment.aed == true && (
                          <Dropdown.Item className="" onClick={handleExportAed}>
                            AED
                          </Dropdown.Item>
                        )}
                      </Dropdown.Menu>
                    </Dropdown>
                  )} */}
              </div>
            </div>

            {/* tabs */}
            {/* <Box className="bg-primary mt-3">
              <div className="d-flex border-bottom border-secondary">
                {Object.values(AccountSiteTab).map((tabItem, i) => (
                  <div
                    role="button"
                    key={i}
                    className={"text-light py-2 px-3"}
                    style={{
                      backgroundColor: `${
                        tabItem == currentTab ? "#26AEE0" : "#0C71C3"
                      }`,
                    }}
                    onClick={() => handleTab(tabItem)}
                  >
                    {tabItem}
                  </div>
                ))}
              </div>
            </Box> */}

            <Box>
              <div className="d-flex border-secondary">
                <SitesTab
                  newSupportUrl={`/account/new-support/?accountId=${editSiteData?.siteInfo?.account_id}&siteId=${siteId}&sectionName=site`}
                  name={editSiteData?.siteInfo?.account_site_name}
                  accountId={editSiteData?.siteInfo?.account_id}
                  siteId={siteId}
                  siteData={editSiteData}
                  setSiteData={setSiteData}
                  billingData={billingData}
                  shippingData={shippingData}
                  trainingData={trainingData}
                  siteHoursData={siteHoursData}
                  aedUnits={aedUnits}
                  coordinatorData={coordinatorData}
                  fetch={fetch}
                  type={"SITE"}
                  tabTbldata={tabTbldata}
                  setTabTbldata={setTabTbldata}
                  setEditSiteAdmin={setEditSiteAdmin}
                  support_api={
                    Number(getPer()) === 1
                      ? "support/ticket-by-site/" + siteId
                      : "user/support-list-for-site/" + siteId
                  }
                  aedAccessoryCount={aedAccessoryCount}
                />
              </div>
            </Box>
            <SupportSymbol
              document_url={`/account/site-details/${siteId}/Documents`}
              note_api={`/account/new-note/?account_id=${editSiteData?.siteInfo?.account_id}&site_id=${siteId}`}
              newSupportUrl={`/account/new-support/?accountId=${editSiteData?.siteInfo?.account_id}&siteId=${siteId}&sectionName=site`}
              stateData={{
                type: "site",
                site_id: siteId,
                accountId: editSiteData?.siteInfo?.account_id || 0,
                contactId: 0,
                accountName: "",
                aedId: 0,
                support_name: editSiteData?.siteInfo?.account_site_name,
              }}
            />
          </div>
        </>
      )}
    </>
  );
}
