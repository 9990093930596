/*
import React, { useState, useEffect,forwardRef,useRef } from "react";
import DatePicker from "react-datepicker";
import moment from "moment";
import MaskedInput from 'react-text-mask'
import "react-datepicker/dist/react-datepicker.css";
import { CalendarIcon } from "../../helper";

const CustomInput = forwardRef(({isRequired,isFilled, ...props}, ref) => (
    <MaskedInput
      mask={[/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/]} 
      {...props}
      ref={ref}
      required={isRequired}
    />
));

function CommonDatePicker({
  calName,
  CalVal,
  HandleChange,
   disabled = false,
   isRequired = false,
   minDate,
   maxDate,
}) {
  // const inputReference = useRef(null);
  const [DateValueState, setDateValueState] = useState(null);
  const [isOpen, setIsOpen] = useState(false); // State to track if date picker is open
  const [isFilled, setIsFilled] = useState(false);
  const inputRef = useRef(null);

  const handleCalendarChange = (value) => {
    if (!value) return "N/A";
    const date = moment(value).format("YYYY-MM-DD");

    if(date.toLowerCase()=='invalid date') {
      return "N/A";
    }

    const v1 = new Date(value);
    HandleChange(calName, date);
    setDateValueState(v1);
    setIsFilled(true); 
    setIsOpen(false)
  };


  useEffect(() => {
    if (!CalVal || CalVal=='unknown') return ;
    if(moment(CalVal).format("MM/DD/YYYY") === 'Invalid Date') return "N/A";
    const v1 = moment(CalVal).format("MM/DD/YYYY");
    handleCalendarChange(v1);
  }, [CalVal]);

  return (
    <div className={`d-flex align-items-center calendar-input-btn calendar-input-btn-1012 ${disabled ? 'disabled-date':''}`} 
    style={{
      border: (isRequired && !isFilled) ? '1px solid red' : '',
    }}>

      <DatePicker
        open={isOpen}
        selected={DateValueState}
        onChange={handleCalendarChange}
        inputRef={inputRef}
        maxDate={maxDate}
        minDate={minDate}
        disabled={disabled}
        onClickOutside={() => setIsOpen(false)}
        shouldCloseOnSelect={true}
        customInput={
          <CustomInput 
            inputRef={inputRef}
            disabled={disabled}
            isRequired={isRequired}
            isFilled={isFilled}
            />
        }
      />  
       
        <span className="cl-name" onClick={() => 
          (disabled) ? "" :  setIsOpen(!isOpen)
          } ><CalendarIcon/></span>
    </div>
  );
}

export default CommonDatePicker;
*/


// -----------------------------------------  having on clear issue in above code 



import React, { useState, useEffect, forwardRef, useRef } from "react";
import DatePicker from "react-datepicker";
import moment from "moment";
import MaskedInput from 'react-text-mask'
import "react-datepicker/dist/react-datepicker.css";
import { CalendarIcon } from "../../helper";

const CustomInput = forwardRef(({isRequired, isFilled, value, onChange, onBlur, ...props}, ref) => {
  const handleInputChange = (e) => {
    const newValue = e.target.value;
    onChange(e);
    
    // Check if the input is completely empty (handles both gradual and select-all deletion)
    if (newValue === '' || newValue.replace(/[^0-9]/g, '') === '') {
      onChange(null);
    }
  };

  const handleBlur = (e) => {
    const newValue = e.target.value;
    // Additional check on blur to catch any lingering incomplete inputs
    if (newValue.includes('_') || newValue.replace(/[^0-9]/g, '').length !== 8) {
      onChange(null);
    }
    if (onBlur) {
      onBlur(e);
    }
  };

  return (
    <MaskedInput
      mask={[/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/]}
      {...props}
      ref={ref}
      required={isRequired}
      value={value}
      onChange={handleInputChange}
      onBlur={handleBlur}
    />
  );
});

function CommonDatePicker({
  calName,
  CalVal,
  HandleChange,
  disable = false,
  isRequired = false,
  minDate,
  maxDate,
}) {
  const [DateValueState, setDateValueState] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [isFilled, setIsFilled] = useState(false);
  const inputRef = useRef(null);

  const handleCalendarChange = (value) => {
    if (!value) {
      HandleChange(calName, null);
      setDateValueState(null);
      setIsFilled(false);
    } else {
      const date = moment(value).isValid() ? moment(value).format("YYYY-MM-DD") : null;
      
      if (date) {
        HandleChange(calName, date);
        setDateValueState(value);
        setIsFilled(true);
      } else {
        HandleChange(calName, null);
        setDateValueState(null);
        setIsFilled(false);
      }
    }
    
    setIsOpen(false);
  };

  useEffect(() => {
    if (!CalVal || CalVal === 'unknown') {
      setDateValueState(null);
      setIsFilled(false);
    } else {
      const parsedDate = moment(CalVal);
      if (parsedDate.isValid()) {
        setDateValueState(parsedDate.toDate());
        setIsFilled(true);
      } else {
        setDateValueState(null);
        setIsFilled(false);
      }
    }
  }, [CalVal]);

  return (
    <div className={`d-flex align-items-center calendar-input-btn calendar-input-btn-1012 ${disable ? 'disabled-date':''}`}
     style={{
      border: (isRequired && !isFilled) ? '1px solid red' : '',
    }}>
      
      <DatePicker
        open={isOpen}
        selected={DateValueState}
        onChange={handleCalendarChange}
        inputRef={inputRef}
        maxDate={maxDate}
        minDate={minDate}
        disabled={disable}
        onClickOutside={() => setIsOpen(false)}
        shouldCloseOnSelect={true}
        customInput={
          <CustomInput
            inputRef={inputRef}
            disabled={disable}
            isRequired={isRequired}
            isFilled={isFilled}
          />
        }
      />
      
      <span className="cl-name" onClick={() => !disable && setIsOpen(!isOpen)}>
        <CalendarIcon/>
      </span>
    </div>
  );
}

export default CommonDatePicker;