import React from "react";
import EditGeneralInfo from "./EditGeneralInfo";
import EditExternalRMSInfo from "./EditExternalRMSInfo";
import EditOutOfService from "./EditOutOfService";
import EditStorageInformation from "./EditStorageInformation";
import EditBuiltInRMSInformation from "./EditBuiltInRMSInformation";

function EditAedForm({
  spareUpdate,
  setSpareUpdate,
  addRemoveBtn,
  AccountList,
  BrandList,
  formData,
  setFormData,
  setPermission,
  Permissins,
  RmsDropdown,
  all_condition_true,
  is_edit = false,
  DefaultValue,
  aedList,
  loanerList,
  setAccId,
  AccId,
  siteId,
  handleCheckBox,
  handleInput,
  addMorebattery_info,
}) {
  return (
    <>
      <div className="">
        <EditGeneralInfo
          spareUpdate={spareUpdate}
          setSpareUpdate={setSpareUpdate}
          addRemoveBtn={addRemoveBtn}
          BrandList={BrandList}
          AccountList={AccountList}
          formData={formData}
          setFormData={setFormData}
          setPermission={setPermission}
          Permissins={Permissins}
          is_edit={is_edit}
          DefaultValue={DefaultValue}
          setAccId={setAccId}
          AccId={AccId}
        />
        {formData?.out_of_service_toggle || all_condition_true ? (
          <EditOutOfService
            BrandList={BrandList}
            AccountList={AccountList}
            formData={formData}
            setFormData={setFormData}
            aedList={aedList}
            loanerList={loanerList}
            siteId={siteId}
          />
        ) : (
          ""
        )}
        <EditStorageInformation
          BrandList={BrandList}
          AccountList={AccountList}
          formData={formData}
          setFormData={setFormData}
          keyName={"keyName"}
          Permissins={Permissins}
        />

        {Permissins?.has_builtin_rms || all_condition_true ? (
          <div
            className=" bg-gray py-4 px-4 mt-4"
            id="built_in_RMS_information"
          >
            <h2 className="heading">{"Built-In RMS Information"} </h2>

            {formData?.BuiltInRMSInformation?.map((item, index) => (
              <EditBuiltInRMSInformation
                keyName={"BuiltInRMSInformation"}
                title="BuiltInRMSInformation"
                crrIndex={index}
                formData={formData}
                setFormData={setFormData}
                handleCheckBox={handleCheckBox}
                handleInput={handleInput}
                crrFormData={item}
                addMore={addMorebattery_info}
              />
            ))}
          </div>
        ) : (
          ""
        )}

        {formData?.RMS_toggle || all_condition_true ? (
          <EditExternalRMSInfo
            BrandList={BrandList}
            AccountList={AccountList}
            formData={formData}
            setFormData={setFormData}
            RmsDropdown={RmsDropdown}
          />
        ) : (
          ""
        )}
      </div>
    </>
  );
}

export default EditAedForm;
