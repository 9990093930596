import React, { useState } from "react";
import { Box, Button } from "@mui/material";
import PreviewTable from "../../Table/PreviewTable";
import { toast } from "react-toastify";
import * as XLSX from "xlsx";
import { Form } from "react-bootstrap";
import { BASE_API } from "../../../../common/services";
import ResponseTable from "../../Table/ResponseTable";
import ProgressBar from "../ProgressBar";
import ImportResultsModal from "../Modal/ImportResultsModal";


const AccountTab = () => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileData, setFileData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [resultData, setResultData] = useState([]);
  const [reportModal,setreportModal] = useState(false);
  const [updatedData, setUpdatedData] = useState([]);

  const [rejectedRecords,setRejectedRecords] = useState([]); // stores rejcteded and failed records 

  const handleFileChange = (e) => {
    // const file = e.target.files[0];
    const fileInput = e.target;
    const file = fileInput.files[0];
  
    fileInput.value = null;   // Reset file input

    if (file) {
      const maxSizeInBytes = 26214400; // 25 MB

      if (file.size > maxSizeInBytes) {
        toast.error("File size must be less than 25MB.");
        setSelectedFile(null);
      } else {
        setSelectedFile(file);
        setResultData([]);
      }
    } else {
      setSelectedFile(null);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!selectedFile) {
      toast.error("Please select a file.");
      return;
    }

    setLoading(true);
    try {
      const reader = new FileReader();
      reader.onload = (e) => {
        const fileData = e.target.result;
        const workbook = XLSX.read(fileData, { type: "binary" });
        const sheetName = workbook.SheetNames[0]; // Get the first sheet
        const sheet = workbook.Sheets[sheetName];
        const jsonData = XLSX.utils.sheet_to_json(sheet, { defval: null, raw: false }); // Convert sheet to JSON data
        setFileData(jsonData); // Store data in the state
      };
      reader.readAsBinaryString(selectedFile); // Read the file as binary string
    } catch (error) {
      toast.error("Error processing file.");
    } finally {
      setLoading(false);
    }
  };

  const handleUpload = async () => {
    if (!selectedFile) {
      return;
    }

    const formData = new FormData();
    formData.append("file", selectedFile);

    try {
      setLoading(true);
      const token = localStorage.getItem("ross_token");
      if (!token) throw new Error("No Token found");

      const res = await fetch(`${BASE_API}import/account-import`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      });

      if (res.status) {
        const result = await res.json();

        const successRecords = result?.data?.successful_records || [];
        if(successRecords.length > 0 ){
          toast.success("File uploaded successfully!");
        }  else{
          toast.error( result?.msg || "No Records Successfully Imported!");
        }

        const rsData = result.data || [];

        // Extracting the "record" only from the rejected_records.
        const rejectedData = rsData?.rejected_records ? rsData?.rejected_records.map((item) => item.record) : [];
        const failedData = rsData?.failed_records ? rsData?.failed_records.map((item) => item.record) : [];
        const rejectRecords = [...rejectedData, ...failedData];
        setRejectedRecords(rejectRecords);
        setResultData(result?.data);
        setSelectedFile(null);
        setFileData([]);

      } else {
        toast.error("Failed to upload file.");
      }
    } catch (error) {
      toast.error("Error during file upload.");
    } finally {
      setLoading(false);
    }
  };

  const headerColumns = [
    {
      Header: "account_name",
      accessor: "account_name",
      disableSortBy: true,
    },
    {
      Header: "parent_account",
      accessor: "parent_account",
      disableSortBy: true,
    },
    {
      Header: "distributor",
      accessor: "distributor",
      disableSortBy: true,
    },
    {
      Header: "customer_type",
      accessor: "customer_type",
      disableSortBy: true,
    },
    {
      Header: "industry",
      accessor: "industry",
      disableSortBy: true,
    },
    {
      Header: "terms",
      accessor: "terms",
      disableSortBy: true,
    },
    {
      Header: "lead_source",
      accessor: "lead_source",
      disableSortBy: true,
    },
    {
      Header: "website",
      accessor: "website",
      disableSortBy: true,
    },
    {
      Header: "important_note",
      accessor: "important_note",
      disableSortBy: true,
    },
    {
      Header: "product_interest",
      accessor: "product_interest",
      disableSortBy: true,
    },
    {
      Header: "aed_check_length",
      accessor: "aed_check_length",
      disableSortBy: true,
    },
    {
      Header: "extra_fields",
      accessor: "extra_fields",
      disableSortBy: true,
    },
    {
      Header: "extra_field1",
      accessor: "extra_field1",
      disableSortBy: true,
    },
    {
      Header: "extra_field2",
      accessor: "extra_field2",
      disableSortBy: true,
    },
    {
      Header: "account_status",
      accessor: "account_status",
      disableSortBy: true,
    },
  ]

const columns = ["account_name",	"parent_account",	"distributor",	"customer_type",	"industry",	"terms", "lead_source",	"website",	"important_note",	"product_interest",	"aed_check_length",	"extra_fields",	"extra_field1",	"extra_field2", "account_status"];  

const convertToCSV = (columns) => {
  // Create a single row for the columns (no data, just the headers)
  return columns.join(",") + "\n";
};

const handleDownload = (columns, filename = "account-upload.csv") => {
  const csvContent = convertToCSV(columns);
  const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
  const link = document.createElement("a");
  link.href = URL.createObjectURL(blob);
  link.download = filename;
  link.click();
};

  const handleEdit = async() => {
    const convertToCSV = (data) => {
      const entries = Object.values(data);
      const headers = Object.keys(entries[0]);
    
      const rows = entries.map((row) =>
        headers.map((header) => `"${row[header] ?? ""}"`).join(",") 
      );
      return [headers.join(","), ...rows].join("\n");
    };
    
  
    const csvString = convertToCSV(updatedData);
  
    const blob = new Blob([csvString], { type: "text/csv" });
    const formData = new FormData();
    formData.append("file", blob, "editedData.csv");
    
    try {
      setLoading(true);
      const token = localStorage.getItem("ross_token");
      if (!token) throw new Error("No Token found");

      const res = await fetch(`${BASE_API}import/account-import`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      });

      if (res.status) {
        const result = await res.json();

        const successRecords = result?.data?.successful_records || [];
        if(successRecords.length > 0 ){
          toast.success("File uploaded successfully!");
        }  else{
          toast.error( result?.msg || "No Records Successfully Imported!");
        }

        const rsData = result.data || [];

        // Extracting the "record" only from the rejected_records.
        const rejectedData = rsData?.rejected_records ? rsData?.rejected_records.map((item) => item.record) : [];
        const failedData = rsData?.failed_records ? rsData?.failed_records.map((item) => item.record) : [];
        const rejectRecords = [...rejectedData, ...failedData];
        setRejectedRecords(rejectRecords);
        setResultData(result?.data);
        setUpdatedData([]);

      } else {
        toast.error("Failed to upload file.");
      }
    } catch (error) {
      toast.error("Error during file upload.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Box className="d-flex mt-4" style={{ gap: "1rem" }}>
        {/* File Input Section */}
        <Form onSubmit={handleSubmit}>
          <div className="d-flex">
            <Form.Group
              controlId="fileInput"
              className="d-flex align-items-center"
            >
              <div className="d-flex flex-column">
                <Form.Label> Account File*</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Select Input File"
                  defaultValue={selectedFile?.name}
                  readOnly
                  style={{ width: "250px" }}
                />
              </div>
              <div className="ms-2 file-input-div">
                <Button
                  variant="link"
                  style={{
                    border: "none",
                    backgroundColor: "transparent",
                    cursor: "pointer",
                    padding: 0,
                  }}
                >
                  <img
                    src="/assets/images/upload.svg"
                    alt="upload"
                    style={{ width: "30px", height: "30px", marginTop: "30px" }}
                  />
                  <Form.Control
                    type="file"
                    name="file_name"
                    accept=".xlsx, xls, .csv"
                    style={{
                      opacity: 0,
                      position: "absolute",
                      width: "100%",
                      height: "100%",
                      cursor: "pointer",
                    }}
                    onChange={handleFileChange}
                  />
                </Button>
              </div>
            </Form.Group>

            {/* Submit Button */}
            <Button
              type="submit"
              variant="contained"
              color="success"
              sx={{ marginTop: "35px" }}
            >
              Submit
            </Button>
          </div>
        </Form>

        {/* Download Button */}
        <Button 
         variant="contained" 
         color="info" 
         sx={{ marginTop: "35px" }} 
         onClick={() => handleDownload(columns)}
        >
          Download Sample CSV File
        </Button>
      </Box>

      <ImportResultsModal show={reportModal} onHide={()=>setreportModal(false)} results={resultData} />
    
      {resultData && resultData?.summary?.total_records > 0 && (
        <div className="mt-4">
          <div style={{ display: "flex", gap: "0.5rem" }}>

          {(resultData?.summary?.rejected_records > 0 || resultData?.summary?.failed_records > 0)  ? 
            <button
              className="btn btn-warning"
              onClick={() => setreportModal(true)}
              
              style={{
                padding: "0.5rem",
                borderRadius: "4px",
                textAlign: "center",
                width: "180px",
                fontSize: "1rem",
              }}
            >
              See Details
            </button>
            :null}

            {[
              { label: "Total Records", value: resultData?.summary?.total_records || 0, color: "#0d6efd" },
              { label: "Successful Records", value: resultData?.summary?.successful_records || 0, color: "#2e7d32" },
              { label: "Rejected Records", value: (resultData?.summary?.rejected_records || 0) + (resultData?.summary?.failed_records || 0), color: "#D32F2F" },
            ].map((item, idx) => (
              <div
                key={idx}
                style={{
                  backgroundColor: item.color,
                  color: "white",
                  padding: "0.5rem",
                  borderRadius: "4px",
                  textAlign: "center",
                  width: "auto",
                  fontSize: "1rem",
                }}
              >
                <span>{item.label} : {item.value}</span>
              </div>
            ))}
          </div>
        </div>
      )}


      {fileData.length > 0 && (
        <div className="mt-4">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "1rem",
            }}
          >
            <h5> Preview </h5>
            <button
              style={{
                padding: "0.5rem 1rem",
                backgroundColor: "#007bff",
                color: "white",
                border: "none",
                borderRadius: "5px",
                cursor: "pointer",
              }}
              onClick={handleUpload}
            >
              {loading ? "Uploading..." : "Upload File"}
            </button>
          </div>

          <PreviewTable
            tableData={fileData}
            isSearchable={true}
            headerColumns={headerColumns}
            setSelectedFile={setSelectedFile}
          />
        </div>
      )}

  {(resultData?.summary?.rejected_records > 0 || resultData?.summary?.failed_records > 0) && (
    <div className="mt-4">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "1rem",
        }}
      >
       <h5> Rejected Records: </h5>
       <button
        style={{
          padding: "0.5rem 1rem",
          backgroundColor: "#007bff",
          color: "white",
          border: "none",
          borderRadius: "5px",
          cursor: "pointer",
        }}
        onClick={handleEdit}
      >
        {loading ? "Uploading..." : "Upload Changes"}
      </button>
      </div> 

       <ResponseTable
        isSearchable={true}
        tableData={rejectedRecords}
        headerColumns={headerColumns}
        setUpdatedData={setUpdatedData}
      /> 
    </div>
    )}

    {/* Progress Bar */}
    {loading && <ProgressBar />}

  </>
  );
};

export default AccountTab;
